import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { lazy, Suspense, useEffect, useMemo, useState } from "react";
import {
	Redirect,
	Route,
	BrowserRouter as Router,
	Switch,
} from "react-router-dom";
import "./assets/styles/app.scss";
/** @todo find a better way to do toasts */
import InfoAlert from "components/Alerts/InfoAlert";
import ErrorAlert from "./components/Alerts/ErrorAlert";
import SuccessAlert from "./components/Alerts/SuccessAlert";

import { StyledEngineProvider } from "@mui/material/styles";
import NoResourceAvailable from "components/NoResourceAvailable";
import PermissionRoute from "components/RouteComponents/PermissionRoute";
import Login from "pages/Login";
import OneAvantPage from "pages/OneAvant";
import Dashboard from "./components/Dashboard";
import useStoreSessionSelector from "./hooks/useStoreSessionSelector";
import themeData from "./services/theme";
import { ffPermissions } from "providers/Permissions/consts";
import { MicrofrontendCommunication } from "utils/microfrontend-communication";

const MainMenu = lazy(() => import("./pages/MainMenu"));
const PPMainView = lazy(
	() => import("./pages/ProductionPlans/ProductionPlansMainView")
);
const PPSpecificView = lazy(
	() => import("./pages/ProductionPlans/ProductionPlanSpecificView")
);
const RecipeCards = lazy(() =>
	import("./pages/Recipes").then((m) => ({ default: m.RecipeCards }))
);
const RecipeView = lazy(() =>
	import("./pages/Recipes").then((m) => ({ default: m.RecipeView }))
);

const LoadingPage = () => (
	<Box
		sx={{
			inset: 1,
			height: "100%",
			display: "grid",
			placeItems: "center",
		}}
	>
		<CircularProgress size={"50px"} />
	</Box>
);

const App = () => {
	const theme = useMemo(() => createTheme(themeData()), []);
	const session = useStoreSessionSelector();

	const instanceName = window.INSTANCE_NAME || "ff";
	const [oneavantLoaded, setOneavantLoaded] = useState(false);

	useEffect(() => {
		MicrofrontendCommunication.beacon({
			event: "HERE_ARE_PERMISSIONS_OPTIONS",
			target: "other",
			data: ffPermissions,
		});

		MicrofrontendCommunication.listenTo({
			event: "TOKEN_SET",
			source: "all",
			listener: () => {
				setOneavantLoaded(true);
			},
		});
	}, []);

	const isDevelopement = useMemo(() => {
		// check if the domain contains localhost or staging
		return (
			window.location.hostname.includes("localhost") ||
			window.location.hostname.includes("staging")
		);
	}, []);

	return (
		<Box className={"app " + instanceName}>
			{isDevelopement && (
				<Box
					sx={{
						position: "fixed",
						top: 0,
						right: "50%",
						transform: "translateX(50%)",
						backgroundColor: "red",
						color: "white",
						padding: "10px",
						fontSize: "1.5rem",
						fontWeight: "bold",
						zIndex: 10000,
						opacity: 0.5,
						pointerEvents: "none",
					}}
				>
					Development Build
				</Box>
			)}
			<ThemeProvider theme={theme}>
				<StyledEngineProvider injectFirst>
					<CssBaseline />
					<ErrorAlert />
					<SuccessAlert />
					<InfoAlert />

					{!oneavantLoaded ? (
						<OneAvantPage
							internalPath="/auth/listener"
							disabledPathSync={true}
						/>
					) : (
						<Suspense fallback={<LoadingPage />}>
							<Router>
								<Switch>
									<PermissionRoute
										only={["access indorcify"]}
										path="/indorcify"
										component={OneAvantPage}
										accessToken={session.accessToken}
										componentProps={{
											internalPath: "/app/hr/",
										}}
										except={["guest"]}
										otherwise={<Redirect to="/login" />}
									/>

									<PermissionRoute
										only={["access logstock"]}
										path="/logstock"
										component={OneAvantPage}
										accessToken={session.accessToken}
										componentProps={{
											internalPath: "/app/logstock/",
										}}
										except={["guest"]}
										otherwise={<Redirect to="/login" />}
									/>

									<PermissionRoute
										exact
										path="/login"
										component={Login}
										only={["guest"]}
										otherwise={<Redirect to="/" />}
									/>
									<PermissionRoute
										path="/"
										except={["guest"]}
										otherwise={<Redirect to="/login" />}
									>
										<Dashboard>
											<Suspense fallback={<LoadingPage />}>
												<Switch>
													{/* <PermissionRoute
														only={["CO.US.M"]}
														accessToken={session.accessToken}
														component={OneAvantPage}
														componentProps={{
															internalPath: "/settings/roles",
															strictPath: "/settings/roles",
															hideSidePanel: true,
														}}
														path="/manageAccount/roles"
													/>

													<PermissionRoute
														only={["CO.US.M"]}
														accessToken={session.accessToken}
														component={OneAvantPage}
														componentProps={{
															internalPath: "/settings/users",
															strictPath: "/settings/users",
															hideSidePanel: true,
															onPathLeave: (path: string, _history: any) => {
																if (path.includes("/settings/roles")) {
																	_history.replace("/manageAccount/roles");
																}
															},
														}}
														path={"/manageAccount"}
													/> */}

													<PermissionRoute
														accessToken={session.accessToken}
														component={OneAvantPage}
														componentProps={{
															internalPath: "/settings",
															strictPath: "/settings",
															hideSidePanel: true,
														}}
														path="/settings"
													/>

													<PermissionRoute
														only={["access kpi dashboard"]}
														exact
														path="/weeklyStatistics"
														component={MainMenu}
														accessToken={session.accessToken}
													/>

													<PermissionRoute
														only={["LS.SUP.M"]}
														accessToken={session.accessToken}
														path="/suppliers"
														component={OneAvantPage}
														componentProps={{
															internalPath: "/app/logstock/suppliers",
															strictPath: "/app/logstock/suppliers",
															hideSidePanel: true,
														}}
													/>

													<PermissionRoute
														only={["access production plans"]}
														exact
														path="/productionPlans"
														component={PPMainView}
														accessToken={session.accessToken}
													/>

													<PermissionRoute
														only={["access production plans"]}
														exact
														path="/productionPlans/add"
														component={PPSpecificView}
														accessToken={session.accessToken}
													/>

													<PermissionRoute
														only={["access production plans"]}
														exact
														path="/productionPlans/:correlationId/plan/:planId"
														component={PPSpecificView}
														accessToken={session.accessToken}
													/>

													<PermissionRoute
														only={["LS.MAT.M"]}
														path="/materials"
														accessToken={session.accessToken}
														component={OneAvantPage}
														componentProps={{
															internalPath: "/app/logstock/materials",
															strictPath: "/app/logstock/materials",
															hideSidePanel: true,
														}}
													/>

													<PermissionRoute
														only={["access recipe cards"]}
														exact
														path="/recipeCards"
														component={RecipeCards}
														accessToken={session.accessToken}
													/>

													<PermissionRoute
														only={["access recipe cards"]}
														exact
														path="/recipeCards/:recipeNumber"
														component={RecipeView}
														accessToken={session.accessToken}
													/>

													<PermissionRoute
														only={["access recipe cards"]}
														exact
														path="/recipeCards/id/:recipeId"
														component={RecipeView}
														accessToken={session.accessToken}
													/>

													<PermissionRoute
														only={["access kpi dashboard"]}
														path="/"
														exact
													>
														<Redirect to="/weeklyStatistics" />
													</PermissionRoute>

													<Route path="">
														<NoResourceAvailable message="Page not found" />
													</Route>
												</Switch>
											</Suspense>
										</Dashboard>
									</PermissionRoute>
								</Switch>
							</Router>
						</Suspense>
					)}
				</StyledEngineProvider>
			</ThemeProvider>
		</Box>
	);
};

export default App;
