import React, { forwardRef } from "react";
import "./CustomButton.scss";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

// @to-do - This is being used at many places. Find all the possible props and define them until then using 'any'
const CustomButton = forwardRef((props: any, ref) => {
	const { loading, className, children, onClick, disabled, type, ...others } =
		props;

	return (
		<Button
			className={`customButton ${className} ${disabled && "disabledButton"}`}
			component="button"
			disabled={loading || disabled}
			onClick={onClick}
			type={type ? type : "button"}
			{...others}
			ref={ref}
		>
			{loading ? (
				<CircularProgress className="loader" size={30} thickness={5} />
			) : (
				children
			)}
		</Button>
	);
});

export default CustomButton;
